import unity from "@images/extended-reality/unity.svg";
import googleVR from "@images/extended-reality/googleVR.svg";
import googleARcore from "@images/extended-reality/googleArcore.svg";
import realityKit from "@images/extended-reality/realityKit.svg";
import appleARKit from "@images/extended-reality/appleArkit.svg";
import golocker from "@images/common/goLocker-logo.png"
import lulu from "@images/common/lulu.png"
import esooko from "@images/ai/esooko.png"
import firstBenefit from "@images/common/benefit1.svg";
import thirdBenefit from "@images/common/benefit3.svg";
import hat from "@images/common/hat.svg";
import tax from "@images/common/tax.svg";

export const DELAYED_RENDER_TIME = 100;
export const XR_BACKGROUND_IMAGE_TITLE = "Extended Reality";
export const XR_BACKGROUND_IMAGE_DESCRIPTION = "Extended Reality (XR) enhances modern digital products by integrating Augmented Reality (AR), Virtual Reality (VR), and Mixed Reality (MR) to create immersive, interactive experiences. AR enhances product visualization, VR offers immersive simulations, and MR blends digital and physical worlds for innovative user interactions. This drives engagement, boosts sales, and enhances user satisfaction in digital products.";
export const CATCH_UP_SECTION_TITLE = "Get in touch";
export const CATCH_UP_SECTION_DESCRIPTION = "Complete contact form, and we will contact you within 24 hours";
export const CONTACT_US_BUTTON_TEXT = "Contact us";
export const EXPERIENCE_CELLS_SECTION_TITLE = "Our experience";
export const EXPERIENCE_CELLS_SECTION_DESCRIPTION = "Discover a realm of innovation and immersive experiences with our extended reality solutions, ranging from state-of-the-art AR & VR simulations to interactive AR books.";
export const FEEDBACK_SECTION_TITLE = "Our Clients say";
export const INDUSTRY_SECTION_TITLE = "Industry-driven XR development";
export const INDUSTRY_SECTION_DESCRIPTION = "We can bring any XR project to life in any industry. Our current experience is based on work with the following industries:";
export const FLIPPABLE_BENEFITS_SECTION_TITLE = "Why choose Impressit?";
export const INLINE_BENEFITS_SECTION_TITLE = "Benefits you get with XR development";
export const SERVICES_SECTION_TITLE = "Our services";
export const TECH_STACK_SECTION_TITLE = "Our tech stack";
export const WE_WORK_WITH_SECTION_TITLE = "We work with XR devices";
export const WE_WORK_WITH_BORDER_COLOR = "#C4B180";

export const VIDEO_SECTION_TEXT_ADDITIONAL_STYLES = {
    padding: "0 16rem"
}

export const TECHNOLOGY_STACK = [
    { name: "Unity", icon: unity },
    { name: "Google VR", icon: googleVR },
    { name: "Google ARCore", icon: googleARcore },
    { name: "RealityKit", icon: realityKit },
    { name: "Apple's ARKit", icon: appleARKit },
];

export const EXPERIENCE_CELLS = [
    { title: "AR & VR Simulations", description: "Experience immersive environments for enhanced training and interactive demonstrations." },
    { title: "UI/UX Design for AR", description: "Create intuitive interfaces optimized for seamless augmented reality interactions." },
    { title: "360 Degrees’ Video", description: "Immerse viewers in panoramic storytelling experiences for engaging content." },
    { title: "Maps (Geolocation)", description: "Enable precise location-based experiences and services for enhanced user engagement." },
    { title: "Image Recognition", description: "Extract valuable insights from visual data for improved inventory management and personalized experiences." },
    { title: "AR Books", description: "Enhance traditional reading experiences with interactive and immersive content for enriched learning and engagement." },
];

export const WE_WORK_WITH_CELLS = [
    { title: "Apple Vision Pro", description: "Experience immersive environments for enhanced training and interactive demonstrations." },
    { title: "Meta Quest", description: "Create intuitive interfaces optimized for seamless augmented reality interactions." },
    { title: "Smartphones", description: "Immerse viewers in panoramic storytelling experiences for engaging content." },
    { title: "Tablets", description: "Enable precise location-based experiences and services for enhanced user engagement." },
]

export const BENEFIT_ITEMS = [
    { title: "Enhanced User Engagement", description: "AR, VR, and Mixed Reality create immersive experiences that captivate users, leading to increased engagement and longer interaction times with digital products." },
    { title: "Improved Product Visualization", description: "AR and VR enable realistic product visualizations, allowing users to interact with and explore products before making purchasing decisions. Mixed Reality merges digital and physical elements for even more detailed visualizations." },
    { title: "Effective Training and Simulation", description: "VR provides immersive training simulations across industries like healthcare. AR enhances on-the-job training with real-time guidance and information overlay. Mixed Reality combines physical environments with digital elements for comprehensive training experiences." },
    { title: "Personalized Experiences", description: "AR and VR technologies can personalize experiences based on user preferences and behaviors, offering tailored content and interactions. Mixed Reality further personalizes experiences by integrating real-world elements into digital environments." },
    { title: "Innovative Marketing and Branding", description: "AR enhances marketing campaigns with interactive product demonstrations and virtual try-on experiences. VR creates immersive brand experiences, while Mixed Reality offers innovative marketing opportunities by blending digital content with real-world settings." }
];

export const FEEDBACK_ITEMS = [
    { title: "Their speed and knowledge were impressive", author: "Ross Ducat", role: "Founder & CEO, Lulu Light", image: { icon: lulu, height: 48, width: 61 }},
    { title: "They’ve understood our overall business needs, and they’ve been flexible with us", author: "Jacob Simons", role: "Founder & CEO, Esooko", image: { icon: esooko, height: 20, width: 148 }},
];

export const FEEDBACK_SECTION_ITEMS = [
  {
    member: "Ross Ducat",
    position: "Founder & CEO, Lulu Light",
    logoSrc: lulu,
    feedbackText: "Their speed and knowledge were impressive",
  },
  {
    member: "Sherin Davis",
    position: "Chief Product Officer, GoLocker",
    logoSrc: golocker,
    feedbackText: "Overall, they are great partners",
  },
]

export const INDUSTRY_ITEMS = [
    { title: "Augmented Reality in Healthcare", description: "Augmented reality empowers medical education, surgery, and patient care. Students train with virtual patients, surgeons gain real-time anatomical insights, and patients receive interactive health visualizations. AR transforms healthcare, one layer at a time." },
    { title: "Virtual Reality in Healthcare", description: "VR applications in healthcare are diverse and impactful. From medical training and surgical simulations to pain management and phobia treatment, VR offers invaluable tools for both patients and professionals." },
    { title: "Augmented Reality in Real Estate", description: "Unleash real estate's future with AR. Overlay 3D models, test furniture, & refine renovations live. Data-driven decisions & enhanced engagement, pixel by pixel. Experience the property journey, transformed." },
    { title: "Virtual Reality in Design", description: "VR empowers architects and designers to navigate 3D models of their creations before construction begins. This fosters better collaboration, early identification of potential issues, and the development of more efficient and optimized designs." },
];

export const SERVICES = [
    { title: "Product development", description: "Immersive Exploration. Comprehensive Development. Leading-Edge Outcomes. We dive into the forefront of Extended Reality technologies, meticulously crafting experiences that push boundaries and surpass conventional limits.", href: "/product-development", buttonText: "See how it works" },
    { title: "Dedicated teams", description: "Craft groundbreaking XR experiences. Our VR/AR experts seamlessly integrate, pushing boundaries & maximizing potential. Shape the future with cutting-edge XR solutions.", href: "/dedicated-teams",  buttonText: "Read about the teams" }
];

export const WHY_CHOOSE_ITEMS = [
    { title: "Commitment", iconFromMarkupPath: firstBenefit, description: "At Impressit, we're dedicated to supporting you throughout every phase of the XR development lifecycle. Your success is our priority, and we're as determined as you are to see your business thrive." },
    { title: "Time & costs savings", iconFromMarkupPath: tax, description: "Investing in premium XR development services now means avoiding future product issues. With Impressit, you'll benefit from solid, high-quality XR products that save you time and money in the long run. " },
    { title: "Reduced Risks", iconFromMarkupPath: thirdBenefit, description: "While opting for freelance XR developers might seem like a cost-effective choice, it comes with inherent risks such as security vulnerabilities and poorly written code. By partnering with Impressit, you mitigate these risks, benefiting from our established team's expertise, streamlined documentation, and code quality assurance." },
    { title: "Experience", iconFromMarkupPath: hat, description: "We recognize the importance of transparency and collaboration in the XR development process. Our experienced team is dedicated to sharing our knowledge and expertise with you, offering comprehensive consultation at every stage of your XR project's development journey." },
];
